/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
<template>
    <div class="vertical-fixed-scroll">
        <slot />
    </div>
</template>

<script>

console.log("2")
export default {
    name: 'VerticalFixedScroll',
};
</script>

<style lang="scss" scoped>
    .vertical-fixed-scroll {
        // border:solid 5px #0e0ece;
        flex: 1 1 auto;
        height: 0;
        overflow: auto;
    }
</style>
  